<!-- /**
 * @description: PDF视图详情页
 * 功能：
 * 1、采购单，报价单，发票，订单对象的详情页新增PDF视图
 * 开发人员：张凤
 * 开始时间：2022-7-11
 * 结束时间：2022-7-31
 */ -->
<template>
  <div class="view-content">
    <!-- 左侧列表视图 -->
    <div class="leftcontent" v-show="isShowList">
      <keep-alive>
        <component
          :is="isCompontent"
          class="left-view"
          ref="listViewRef"
          @accountData="accountData"
        ></component>
      </keep-alive>
    </div>
    <div :class="isShowList?'rightdetail':'allrightdetail'">
      <router-view
        :data-id="id"
        ref="detailViewRef"
        :accountvalue="accountvalue"
      ></router-view>
    </div>
  </div>
</template>
<script>
/**
 * PDF视图引用组件
 * 功能：
 * 1、左侧列表视图
 * 2、右侧PDF视图详情页
 */
import listView from "./listView/index";

export default {
  components: {
    listView,
  },
  data() {
    return {
      isCompontent:'listView',
      // 视图所在id
      objId: this.$route.params.id,
      // 视图Id
      viewId: this.$route.query.viewId,
      // 对象api
      objectApi: this.$route.query.objectApi,
      // // 视图类型
      // 对象前缀
      prefix: this.$route.query.prefix,
      // 编辑记录id
      id: "",
      isShowList: true, //是否显示列表视图
      accountvalue: [], //左侧列表数据用来获取客户id
    };
  },
  beforeRouteEnter(to, from, next) {
    // 跳转到pdf视图详情页时，打开子路由显示右侧详情页
    if ( to.fullPath.indexOf("/commonObjects/pdfViewDetail") > -1 ) {
      next((vm) => {
        vm.$router.push({
          path: `/commonObjects/pdfViewDetail/${to.params.id}/pdfInformation`,
          query: {
            objectApi: to.query.objectApi,
            objId: to.query.objId,
            prefix: to.query.prefix,
            viewId: to.query.viewId,
          },
        });
      });
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (
      to.fullPath.indexOf("/commonObjects/pdfViewDetail") !== -1 &&
      from.fullPath.indexOf("/commonObjects/pdfViewDetail") !== -1
    ) {
      // 复制PDF视图详情页时，跳转新页面刷新数据
      if (to.fullPath.indexOf("pdfInformation") < 0) {
        this.$refs.listViewRef.init();
        this.$refs.listViewRef.getViewList(true);
        this.$refs.listViewRef.prefix = to.query.prefix;
        this.$refs.listViewRef.pageNum = 1;
      }
    }
    if (to.query.viewId) {
      this.viewId = to.query.viewId;
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    // 离开PDF视图详情页清空视图id等数据
    if (to.fullPath.indexOf("/commonObjects/pdfViewDetail") === -1) {
      this.$store.commit("setObjectApi", "");
      this.$store.commit("setObjId", "");
      this.$store.commit("setPrefix", "");
      this.$store.commit("setIsPdfViewStatus", "");
      this.$store.commit("setRecordStatus", "");
      this.$store.commit("setObjectNumber", "");
    }
    next();
  },
  created() {},

  methods: {
    // 回传获取客户名称ID
    accountData(data) {
      this.accountvalue = data;
    },
    // 初始化页面判断浏览器宽度
    getWidth() {
      let winWidth;
      if (window.innerWidth) {
        winWidth = window.innerWidth;
      } else if (document.body && document.body.clientWidth) {
        winWidth = document.body.clientWidth;
      }
      if (winWidth < 1300) {
        this.isShowList = false;
      } else {
        this.isShowList = true;
      }
    },
  },
  mounted() {
    this.getWidth();
    this.$bus.$on("windowResize", (offsetHeight, offsetWidth) => {
      if (offsetWidth < 1300) {
        this.isShowList = false;
      } else {
        this.isShowList = true;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.view-content {
  width: 100%;
  display: flex;
  height: 100%;
  background: #fff;
  .leftcontent {
    width: 320px;
  }
  .rightdetail {
    width: calc(100% - 320px);
  }
  .allrightdetail{
    width:100%;
  }
  .right-view {
    width: 100%;
    background-color: #fff;
    .view-top-box {
      height: 64px;
      opacity: 1;
      border: 1px solid #e1e1e1;
      display: flex;
      justify-content: space-between;
      .record-code {
        font-size: 20px;
        font-weight: bold;
        color: #080707;
        padding: 22px 0 16px 31px;
        line-height: 23px;
      }
      .operation-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        .see-view-btn {
          margin-right: 54px;
          font-size: 14px;
          font-weight: 400;
          color: #000000;
        }
        .icon-view {
          margin-right: 8px;
          display: flex;
          .icon-button {
            width: 32px;
            height: 32px;
            background: #ffffff;
            opacity: 1;
            border: 1px solid #e1e1e1;
            border-left: none;
            svg {
              width: 16px;
              height: 16px;
              padding: 8px;
            }
          }
          .left-radius {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            border-left: 1px solid #e1e1e1;
          }
          .right-radius {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }
        }
        .more-btn {
          min-width: 56px;
          height: 32px;
          background: #ffffff;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          border: 1px solid #e1e1e1;
          margin-right: 42px;
          border: 1px solid #e1e1e1;
          font-size: 14px;
          font-weight: 400;
          color: #080707;
          line-height: 16px;
          padding: 7px 12px;
        }
      }
    }
  }
  .tab-content {
    .el-tabs__nav-scroll {
      height: 44px;
      line-height: 44px;
    }
  }
  .left-view {
    width: 320px;
    height: 100%;
  }
}
</style>
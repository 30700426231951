<template>
  <!-- 开发人员：张亚欣 -->
  <div class="objectTableMain">
    <div class="object_box">
      <!-- 顶部按钮功能操作区域 -->
      <div class="topContent">
        <!-- 左侧快捷操作按钮 -->
        <div class="left-content">
          <input id="viewId" type="hidden" v-model="viewId" />
          <input id="ids" type="hidden" v-model="ids" />
          <!-- 视图切换器 -->
          <ViewToggle
            ref="chooseviewRef"
            v-show="selectedList.length === 0"
            class="pull-left"
            :viewId="viewId"
            :view-list="viewList"
            :view-name="viewName"
            :objId="objId"
            :prefix="prefix"
            :type="type"
            :create-view="createView"
            :copyForm="copyForm"
            @changeView="changeView"
            @getViewList="getViewList"
            @getViewId="getViewId"
            @refresh="refresh"
            @actives="actives"
            @handleView="handleView(arguments)"
            @openCopyView="openCopyView"
          ></ViewToggle>
          <!-- 多选时批量操作按钮 -->
          <BatchButton
            ref="batchOperationButtons"
            v-show="selectedList.length > 0"
            :pdfviewdetail="'pdfview'"
            :selectedList="selectedList"
            :show-buttons="showBtns"
            :isTag="isTag"
            :tab-name="tabName"
            :type="type"
            :objId="objId"
            :viewId="viewId"
            :view-name="viewName"
            :reportList="reportList"
            :prefix="prefix"
            :viewInfos="viewInfo"
            :totalSize="totalSize"
            :showScreen="showScreen"
            @refresh="refresh"
            @addTag="addTag"
            @planView="planView"
            @tagManage="tagManage"
            @chartsBoard="chartsBoard"
            @print="print"
            @reportBoard="reportBoard"
            @cancelBatchOperation="cancelBatchOperation"
            @refreshAfterBatchDelete="refreshAfterBatchDelete"
          ></BatchButton>
          <!-- 多选时数量统计 -->
          <SelectSize
            v-show="selectedList.length > 0"
            :selectedList="selectedList"
            @cancelBatchOperation="cancelBatchOperation"
          ></SelectSize>
        </div>
        <!-- 视图新增类和普通操作类按钮 -->
        <div class="right-content">
          <!-- 常用按钮 -->
          <HotButton
            v-show="selectedList.length == 0"
            class="pull-right"
            ref="wrapper"
            :selectedList="selectedList"
            :addable="addable"
            :viewId="viewId"
            :prefix="prefix"
            :tabName="tabName"
            :show-buttons="showBtns"
            :other-buttons="showButtons"
            :type="type"
            @add="add"
            @refresh="refresh"
            @change="refresh"
            @createdtemplainit="createdtemplainit"
          ></HotButton>
          <!-- 其他按钮 -->
          <OtherButton
            class="pull-right"
            style="margin-left: 8px"
            ref="wrapper"
            :selectedList="selectedList"
            :addable="addable"
            :viewId="viewId"
            :prefix="prefix"
            :tabName="tabName"
            :show-buttons="showBtns"
            :other-buttons="showButtons"
            :fileShowBtns="fileShowBtns"
            :type="type"
            @add="add"
            @addTag="addTag"
            @refresh="refresh"
            @mailchimp="mailchimp"
            @change="refresh"
            @createdtemplainit="createdtemplainit"
            @export="exportView"
            @print="print"
            @tagManage="tagManage"
            @chartsBoard="chartsBoard"
            @salesFunnel="salesFunnel"
            @promotionOfSalesStage="promotionOfSalesStage"
            @highSpoolAction="highSpoolAction"
            @fileSortFun="fileSortFun"
          ></OtherButton>
        </div>
      </div>
      <!-- 内容主区域 -->
      <div class="main-content-box" ref="mainContentBox" v-loading="viewListDataLoading">
        <!-- 列表 -->
        <!-- <TablePanel
          closeLoadMore
          class="tablePanel"
          ref="tablePanel"
          v-if="!showKanban && !showPlan"
          :editFlag.sync="editFlag"
          :editId="id"
          :isQueryCount="isQueryCount"
          :border="true"
          :showScreen="showScreen"
          :filterField="filterField"
          :show-index="true"
          :checked="true"
          :ismodifyFalg="ismodifyFalg"
          :customHeader="true"
          :prefix="prefix"
          :obj-id="objId"
          :object-api="objectApi"
          :view-id="viewId"
          :view-modify="ismodifyFalg"
          :page-num="pageNum"
          :total="totalSize"
          :page-size-list.sync="pageSize"
          :current-page.sync="skippageNum"
          :table-attr="tableAttr"
          :page-obj="pageObj"
          :table-button="tableBtnList"
          :noDataTip="noDataTip"
          :sortField="sortField"
          :sortDir="sortDir"
          :viewSelectedFieldList="viewSelectedFieldList"
          :viewUnselectedFieldList="viewUnselectedFieldList"
          :pict-loading="pictLoading"
          :enableInlineEdit="userInfoObj.enableInlineEdit !== 'false'"
          @getViewListData="getViewListData"
          @searchViewDetail="searchViewDetail"
          @changeSortFiled="changeSortFiled"
          @changeFilterFiled="changeFilterFiled"
          @wordWrap="wordWrap"
          @locked="locked"
          @edit="edit"
          @del="del"
          @marktemplate="marktemplate"
          @transferOwner="transferOwner"
          @transferClientOwner="transferClientOwner"
          @handleChange="handleChange"
          @saveSelectedField="saveSelectedField"
          @onchange="onchange"
          @refresh="refresh"
        ></TablePanel> -->
        <div class="data-content" >
          <!-- 骨架屏 -->
          <Wang-skeleton
            type="line"
            :options="skeletonOptions"
            v-if="pageNum === 1"
          >
          </Wang-skeleton>
          <div
            class="quate-list"
            v-for="item in pageObj.dataList"
            :label="item"
            :key="item.id"
          >
            <div class="check-box" :class="id == item.id ? 'list-active' : ''">
              <el-checkbox
                v-model="item.checkedWork"
                @change="onchange"
              ></el-checkbox>
            </div>
            <div
              class="title-number"
              :class="id == item.id ? 'list-active' : ''"
              @click="openPdfDetailfn(item)"
            >
              <!-- 客户名称，金额总和 -->
              <div class="quate-title">
                <div class="quate-name">
                  {{ getApiFileValue("name", item) }}
                </div>
                <div class="quote-total">
                  {{ getApiFileValue("total", item) }}
                </div>
              </div>
              <div class="quate-number">
                <!-- 客户名称,日期  -->
                <div class="number-date">
                  <div
                    :v-if="getApiFileValue('number', item) != ''"
                    :class="
                      getApiFileValue('number', item) != '' ? 'blue-number' : ''
                    "
                  >
                    {{ getApiFileValue("number", item) }}
                  </div>
                  <div
                    :v-if="getApiFileValue('date', item) != ''"
                    :class="
                      getApiFileValue('number', item) != '' &&
                      getApiFileValue('date', item) != ''
                        ? 'border-solid'
                        : ''
                    "
                  >
                    {{ getApiFileValue("date", item) }}
                  </div>
                </div>
                <!-- 状态 -->
                <div :class="getApiStatus(item)">
                  {{ getApiFileValue("status", item) }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 分页 -->
        <div class="paginationBox">
          <div class="pagination-total">总数：{{ totalSize }}</div>
          <div class="pagination-page">
            <el-pagination
              background
              small
              @size-change="handleSizeChange"
              :current-page="Number(skippageNum)"
              :page-sizes="[10, 25, 50, 100, 200]"
              :page-size="pageSize"
              layout="sizes"
              :total="totalSize"
            >
            </el-pagination>
            <div class="add-pagenum">
              <el-input
                v-model="skippageNum"
                type="number"
                :max="maxPageNum"
                style="width: 38px; padding: 0"
                placeholder="请输入内容"
              ></el-input>
              <div @click="handleCurrentChange()">
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>

        <!-- 看板视图 -->
        <kanban
          v-if="showKanban && !showPlan"
          ref="kanban"
          @chat="chat"
          @longitude="longitude"
          @activeName="activeName"
          :kanBanData="kanBanData"
          :selectedList="selectedList"
          :ismodifyFalg="ismodifyFalg"
        ></kanban>
      </div>

      <!-- 计划-排班表-->
      <shift-plan
        ref="shiftPlan"
        :viewId="viewId"
        v-if="!showKanban && showPlan"
      ></shift-plan>

      <!-- 删除数据提示 -->
      <el-dialog
        :visible.sync="dialogVisible"
        :title="$t('label.delete')"
        top="15%"
        width="25%"
        :close-on-click-modal="false"
        :before-close="handleClose"
      >
        <span
          style="font-size: 16px; font-weight: bold; word-break: break-word"
        >
          <!-- 确认删除该条数据吗？ -->
          {{ delContent }}
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false" size="mini">
            <!-- 取消 -->
            {{ $t("label.cancel") }}
          </el-button>
          <el-button
            @click="confirmDel"
            type="primary"
            size="mini"
            :loading="isBtnLoading"
          >
            <!-- 删除 -->
            {{ $t("label.delete") }}
          </el-button>
        </span>
      </el-dialog>

      <!-- 更改所有人 -->
      <transfer-owner-dialog
        ref="transferClientOwner"
        :id="id"
        :objId="objId"
        :transfer-deault="transferDeault"
        :owner-type-list="ownerTypeList"
        :owner-raletion-list="ownerRaletionList"
        :transfer-client-owner-dialog="transferClientOwnerDialog"
        @closeTransferOwnerDialog="closeTransferOwnerDialog"
        @saveTransferOwner="saveTransferOwner"
      ></transfer-owner-dialog>

      <!-- 视图新建、编辑 -->
      <CreateEditView
        ref="createEditView"
        :dialongViewTo="dialongViewTo"
        :title.sync="title"
        :viewUnselectedFieldList="viewUnselectedFieldList"
        :viewcell="viewcell"
        :visibFlag.sync="visibFlag"
        :selectedFieldIds.sync="selectedFieldIds"
        :selectedGroupList="selectedGroupList"
        :objFormVal="objForm"
        :checked="checked"
        :isQueueSelect.sync="isQueueSelect"
        :queryQueueValue.sync="queryQueueValue"
        :isQueue="isQueue"
        :queryQueueList="queryQueueList"
        :loading="loading"
        :isUsedForMobile.sync="isUsedForMobile"
        :selLength="selLength"
        :formLabelWidth="formLabelWidth"
        :optionst_one="optionst_one"
        :optionst_two="optionst_two"
        :objId="objId"
        :viewId="enViewId"
        :prefix="prefix"
        :copyForm="copyForm"
        :viewSelectedFieldList="viewSelectedFieldList"
        @closeViewDialog="closeViewDialog"
        @getViewId="getViewId"
      ></CreateEditView>

      <!-- 标签列表 -->
      <LabelView
        class="LabelView"
        v-if="isLabelView"
        :objId="objId"
        :tabName="tabName"
        :getViewListDataParams="getViewListDataParams"
        @tagValue="tagValues"
        @gb="getMsgFormSon"
      ></LabelView>

      <!-- 图表列表 -->
      <Chart
        v-if="isChart"
        class="chart"
        :ismodifyFalg="ismodifyFalg"
        :view-id="viewId"
        :listDashboard="listDashboard"
        :loadingDashboard="loadingDashboard"
      ></Chart>

      <!-- 新增/修改记录 -->
      <CreateEditObj
        ref="createEditObj"
        :prefix="prefix"
        :detailView="'pdf'"
        :id="id"
        :objectApi="objectApi"
        :object-name="tabName"
        :realObjId="objId"
        :isTableEdit="isTableEdit"
        @save="save"
        @refreshRecord="refreshRecord"
      ></CreateEditObj>

      <!-- 添加标签 -->
      <div class="add_label">
        <add-label
          :ids="ids"
          :objId="objId"
          @refresh="refresh"
          @addTag="addTag"
          ref="addlabel"
        ></add-label>
      </div>
    </div>

    <!-- 导出至MailChimp -->
    <MailChimp
      ref="MailChimp"
      :listData="ids"
      :prefix="prefix"
      :viewId="viewId"
      :tabName="tabName"
      @MailChimpSelect="MailChimpSelect"
      :selectedList="selectedList"
      @refresh="refresh"
    ></MailChimp>
    <!-- 开启/关闭公海池弹窗 -->
    <HighSeasPool
      :dialogVisible="highSeasPdialog"
      :objid="objId"
      :highSeaPoolStatus="highSeaPoolStatus"
      @closeDialog="closeDialog"
    >
    </HighSeasPool>
  </div>
</template>

<script>
/**
 * 对象数据的列表展示页
 * 功能：
 *  1.过滤功能，根据视图可以过滤的字段，动态渲染过滤视图，通过过滤条件筛选列表数据
 *  2.新建视图和视图切换功能，视图是不同条件和展示不同字段的组合
 *  3.展示形式切换，一个表格的数据可以用不同的形式展示：列表，看板、分屏
 *  4.关键字搜索，通过关键字过滤数据
 *  5.数据的特殊操作：新建，编辑，删除，更改所有人等等，功能列表跟对象相关联
 */
import { LabelView, TransferOwnerDialog } from "@/components/index";
import Chart from "@/views/commonObjects/components/chart.vue";
import TransferOwnerMixin from "@/mixin/transferOwner.js";
import Kanban from "@/views/commonObjects/components/kanban.vue";
import shiftPlan from "@/views/commonObjects/components/shiftPlan";
import createButton from "@/utils/Button";
import addLabel from "@/components/LabelView/addTag.vue";
import * as types from "@/store/mutations-types";
import MailChimp from "@/components/MailChimp/index.vue";
import filterValueInputType from "@/config/enumCode/inputType.js";
import operator from "@/config/enumCode/operator.js";
import { echoValueTypeConversion } from "@/config/filter/commaProcessing";
import { getMarketseaSwitchState } from "@/views/applicationProgram/api.js";
import CreateEditView from "@/views/Create/CreateView";
import CreateEditObj from "@/views/commonObjects/components/create-edit-obj.vue";
import HighSeasPool from "@/components/ButtonAction/HighSeasPool";
import ViewToggle from "../../../List/ViewToggle/index.vue";

import HotButton from "../../../List/HotButton/index.vue";
import OtherButton from "../../../List/OtherButton/index.vue";
import BatchButton from "../../../List/BatchButton/index.vue";
import SelectSize from "../../../List/SelectSize/index.vue";
import * as CommonObjApi from "../api.js";

export default {
  mixins: [TransferOwnerMixin],
  components: {
    HotButton,
    OtherButton,
    BatchButton,
    SelectSize,
    ViewToggle,
    LabelView,
    Chart,
    Kanban,
    shiftPlan,
    addLabel,
    TransferOwnerDialog,
    MailChimp,
    CreateEditView,
    CreateEditObj,
    HighSeasPool,
  },
  data() {
    return {
      //视图loading
      viewListDataLoading:false,
      // 骨架屏数据
      skeletonOptions: {
        active: true,
        row: 15,
        column: 1,
        width: "360px",
        height: "40px",
        justifyContent: "space-around",
      },
      checkAll: false, //是否全选
      cities: [],
      isIndeterminate: true,
      // 报价单：quote，发票：Invoices
      // 视图所在id
      objId: this.$route.query.objId,
      // 视图Id
      viewId: this.$route.query.viewId,
      // 对象api
      objectApi: this.$route.query.objectApi,
      // // 视图类型
      // 对象前缀
      prefix: this.$route.query.prefix,
      // 是否显示打印、导出按钮
      isExportReport: false,
      // 表格是否开启内联编辑功能，默认开启,如果不是false，都是开启
      userInfoObj: this.$store.state.userInfoObj,
      // 跳转项目任务需要的参数
      projectTasksObjectApi: "",
      // 此对象包含的视图数量
      viewLength: 0,
      // 是否同时满足所有标签
      isAllTag: "",
      // 是否搜索所有
      isallsearch: "false",
      checkFlag: false,
      memberGroup: [],
      // 新建、编辑视图数据
      objForm: {},
      // 新建、编辑组件使用，标识是否是列表页编辑，编辑时保存不跳详情页
      isTableEdit: false,
      // 控制转移所有者变量
      transferClientOwnerDialog: false,
      // 控制table中的加载框
      pictLoading: false,
      // 存储精度的长度
      lengths: 0,
      // 视图新建权限
      createView: false,
      // 是否展示看板视图
      showKanban: false,
      // 是否展示轮班视图
      showPlan: false,
      // 是否显示筛选器
      showScreen: false,
      // 显示标签
      isLabelView: false,
      // 显示图表
      isChart: false,
      // 浏览器tab名称
      tabName: "",
      // 对象api
      // objectApi: this.$route.query.objectApi,
      // // 视图类型
      type: this.$route.query.type,
      // // 对象id
      // objId: this.$route.params.objId,
      // // 对象前缀
      // prefix: this.$route.params.prefix,
      // table表头
      tableAttr: null,
      // table操作按钮集合
      tableBtnList: {
        // 操作
        fixed: "right",
        title: this.$i18n.t("label.operate"),
        buttonList: [
          createButton({
            // 编辑
            label: this.$i18n.t("label.modify"),
            action: "edit",
          }),
          createButton({
            // 删除
            label: this.$i18n.t("label.delete"),
            action: "del",
          }),
          createButton({
            // 更改所有人
            label: this.$i18n.t("label_tabpage_cownerz"),
            action: "transferOwner",
          }),
        ],
      },
      // 页面对象，需要传递给table组件
      pageObj: {
        dataList: [],
      },
      // 控制删除数据dialog显隐
      dialogVisible: false,
      // 视图右侧按钮集合
      showBtns: [],
      fileShowBtns: [], //字段排序按钮
      // 视图列表中是否显示过滤框
      showFilter: false,
      // 布局类型集合：列表、看板、分屏
      viewsTypeList: [
        {
          icon: "List",
          name: this.$i18n.t("vue_label_help_list"),
        },
        {
          icon: "Kanban",
          name: this.$i18n.t("vue_label_commonobjects_view_kanban_view"),
        },
        {
          icon: "splitscreen",
          name: this.$i18n.t("vue_label_commonobjects_view_split_screen_view"),
        },
      ],
      // 需要右上角展示出来的按钮
      showButtons: [],
      // 视图名称
      viewName: "",
      // 视图Id
      // viewId: this.$route.query.viewId,
      // 此对象包含的视图列表
      viewList: [],
      // 页码默认为1
      pageNum: 1,
      // 页码
      skippageNum: 1,
      // 每页展示数据条数
      pageSize: 25,
      // 总记录数
      totalSize: 0,
      // 有无数据标识
      noDataTip: false,
      // 排序字段
      sortField: "",
      // 排序字段名称
      sortFieldName: "",
      // 排序方式：正序、倒序
      sortDir: "asc",
      // 新建视图时，选择的字段集合
      viewSelectedFieldList: [],
      // 新建视图时，未选择的字段集合
      viewUnselectedFieldList: [],
      // 搜索关键字
      searchKeyWord: "",
      // 高级搜索的条件
      conditionValues: "",
      // 搜索标签
      tagIds: "",
      // 全局搜索是否只查询所有人是自己的记录
      myself: "false",
      // 全文检索查询返回值，下一页查询回传
      scrollId: "false",
      // 数据过滤搜索条件
      getViewListDataParams: {},
      // 记录id
      id: this.$route.params.id,
      // 是否开启表格单条数据更新，如果开启必须传记录id
      editFlag: false,
      // 新创建的视图Id
      newViewId: "",
      // 表格选中数据id
      ids: "",
      // 是否启用查找统计
      isQueryCount: false,
      // 视图信息
      viewInfo: {},
      // 表格选中数据集合
      selectedList: [],
      // 标记项目管理的新建按钮是否隐藏
      addable: "",
      // 对象对应的的报表列表
      reportList: [],
      // 报表列表的loading
      loadingReport: false,
      // 获取对象信息中的tag标记
      isTag: "",
      // 判断视图权限
      ismodifyFalg: "",
      // 对象下的仪表板列表
      listDashboard: [],
      // 仪表板列表loading
      loadingDashboard: false,
      // 看板数据
      kanBanData: null,
      // 看板tab值
      activeNames: null,
      // 删除提示语
      delContent: "",
      // 导出链接
      daochuUrl: "",
      // 全选列表数据时存储的数据
      bool: null,
      // 按钮加载中
      isBtnLoading: false,
      // 当前筛选器数据
      curPushData: [],
      // 筛选器获取表头数据
      curHeader: [],
      // 按‘字段名’排序字段
      filterField: null,
      // 新建编辑视图弹窗
      dialongViewTo: false,
      // 新建视图时设置的视图名称
      title: "",
      // 视图标识
      viewcell: "",
      // 新建视图控制显隐变量
      visibFlag: false,
      // 新建时，选择的字段集合
      selectedFieldIds: "",
      // 新建时，选择的组集合
      selectedGroupList: [],
      checked: false,
      // 是否复制表单名字
      copyForm: {
        name: "",
      },

      isQueueSelect: false,
      // 是否应用于移动端
      isUsedForMobile: false,
      // 筛选框1下拉框数据
      optionst_one: [],
      // 筛选框2下拉框数据
      optionst_two: [],
      // 编辑视图时，队列选项值
      queryQueueValue: "",
      // 是否启用队列
      isQueue: null,
      // 队列列表
      queryQueueList: [],
      // 控制是否显示加载框
      loading: false,
      // 表单标签的宽度
      formLabelWidth: "138px",
      // 标记未选字段的长度
      selLength: 0,
      // 编辑或复制视图时用于保存Id
      enViewId: "",
      //存放当前对象是否开启公海池状态
      highSeaPoolStatus: {},
      //控制公海池弹框
      highSeasPdialog: false,
      // 最大页数
      maxPageNum: 1,
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.conditionValues = ""; //切换视图时清空高级筛选条件
    this.curPushData = []; //切换视图时清空原有筛选条件
    this.viewId = to.query.viewId;
    this.type = to.query.type;
    this.objectApi = to.query.objectApi;
    this.objId = to.query.objId;
    this.prefix = to.query.prefix;
    this.totalSize = 0;
    this.pageNum = 1;
    this.skippageNum = 1;
    this.pageObj.dataList = [];
    this.sortField = "";
    this.sortFieldName = "";
    this.searchKeyWord = "";
    this.transferClientOwnerDialog = false;
    this.reportList = []; // 清空报表列表
    this.listDashboard = []; // 清空仪表板列表
    // 给pageSize赋初始值
    // this.pageSize = 50;//切换菜单每页条数保留
    this.$store.commit("setPageSize", 0);
    // if (this.$refs.tablePanel && this.$refs.tablePanel.checkedList) {
    //   this.$refs.tablePanel.checkedList = [];
    // }
    this.selectedList = [];
    // 判断展示表格、看板或者计划轮班视图
    if (this.type === "table") {
      this.showKanban = false;
      this.showPlan = false;
      if (this.$refs.pullRight) {
        this.$refs.pullRight.isBoardView = false;
      }
    } else if (this.type === "kanban") {
      this.showKanban = true;
      this.showPlan = false;
    } else if (this.type === "plan") {
      this.showKanban = false;
      this.showPlan = true;
    }

    // 同一对象下切换列表视图和看板视图时不刷新视图列表
    if (
      to.query.prefix !== from.params.prefix ||
      (this.type === "table" && this.viewId === undefined)
    ) {
      this.getViewList(true);
    } else if (this.type === "table") {
      this.getViewListRecently();
      this.getViewListHeader();
      this.getViewListHeaders();
      this.getViewListData();
      this.getObjectGetObjectInfos();
    }
    next();
    this.business(); // 控制按钮权限
  },
  created() {
    // 判断展示表格、看板或者计划轮班视图
    if (this.type === "table") {
      this.showKanban = false;
      this.showPlan = false;
    } else if (this.type === "kanban") {
      this.showKanban = true;
      this.showPlan = false;
    } else if (this.type === "plan") {
      this.showKanban = false;
      this.showPlan = true;
    }
    // this.objId = this.$route.query.objId;
    this.prefix = this.$route.query.prefix;
    this.getViewList(true);
    this.getCreateView();
    this.checkQueueAndPartners();
  },
  mounted() {
    this.$store.commit("setObjIds", this.objId);
  },
  computed: {},
  methods: {
    // 刷新列表页，右侧详情页显示最新创建的记录
    refreshRecord() {
      this.getViewListData();
    },
    openPdfDetailfn(data) {
      this.$store.commit("setObjectNumber", data?.name);
      this.$Bus.$emit("chooseListItem", data);
      this.id = data.id;
      this.$router.push({
        path: `/commonObjects/pdfViewDetail/${data.id}/pdfInformation`,
        query: {
          objectApi: this.objectApi,
          objId: this.objId,
          prefix: this.prefix,
          viewId:this.viewId,
        },
      });
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.pageObj.dataList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.pageObj.dataList.length;
    },
    /**
     * handleSizeChange：分页pageSize 每页条数改变
     * @param {number} val 改变后的每页展示的数据条数
     * @returns {}
     */
    handleSizeChange(val) {
      // 滚动条回到顶部
      // this.$refs.tableBox.scrollTop = 0;
      this.pageSize = val;
      this.skippageNum = 1;
      this.getViewListData("noClearn");
    },
    // 根据不同对象的状态显示不同的颜色
    getApiStatus(value) {
      // 发票状态
      if (this.objectApi == "quote"&&value.zhuangtai == "草稿") {
        return "status-grey";
      } else if(this.objectApi != "quote" && value.status == "草稿") {
        return "status-grey";
      }else{
        return "status-blue";
      }
    },
    // 不同对象字段取值不同
    getApiFileValue(value, item) {
      if (value == "status") {
        if (this.objectApi == "quote") {
          return item.zhuangtai ? item.zhuangtai : "";
        } else {
          return item.status ? item.status : "";
        }
      } else if (value == "name") {
        if (this.objectApi == "Invoices") {
          return item.accountNameccname ? item.accountNameccname : "";
        } else if (this.objectApi == "cloudccorder") {
          return item.accountidccname ? item.accountidccname : "";
        } else if (this.objectApi == "quote") {
          return item.khmcccname ? item.khmcccname : "";
        } else if (this.objectApi == "cloudccpurchaseorder") {
          return item.vendornameccname ? item.vendornameccname : "";
        }
      } else if (value == "total") {
        if (this.objectApi == "Invoices") {
          return this.getNumberFormat(item.total) || 0;
        } else if (
          this.objectApi == "quote" ||
          this.objectApi == "cloudccorder"
        ) {
          return this.getNumberFormat(item.totalamount) || 0;
        } else if (this.objectApi == "cloudccpurchaseorder") {
          return this.getNumberFormat(item.grandtotal )|| 0;
        }
      } else if (value == "number") {
        if (this.objectApi == "cloudccpurchaseorder") {
          return item.accountidccname ? item.accountidccname : "";
        } else {
          return item.name ? item.name : "";
        }
      } else if (value == "date") {
        if (this.objectApi == "Invoices") {
          return item.invoiceDate ? item.invoiceDate : "";
        } else if (
          this.objectApi == "quote" ||
          this.objectApi == "cloudccpurchaseorder"
        ) {
          return item.startdate ? item.startdate : "";
        } else if (this.objectApi == "cloudccorder") {
          return item.effectivedate ? item.effectivedate : "";
        }
      }
    },
    /**
     * 接口返回多个类型金额，默认取第一个数据
     * CNY123（USD156）=CNY123
     */
    getNumberFormat(value){
      let index=value.indexOf('(');
      if(index>-1){
        let numStr=value.substring(0,index);
        return numStr;
      }else{
        return value;
      }

    },
    /**
     * 批量删除数据后刷新(子组件通过$parent调用)
     */
    refreshAfterBatchDelete() {
      // 重置列表信息
      this.pageObj.dataList = [];
      this.pageNum = 1;
      this.skippageNum = 1;
      // 重置选中统计数据
      this.selectedList = [];
      this.getViewListData();
    },

    /**
     * 获取视图新建权限，当拥有创建权限时，需要显示复制按钮
     */
    getCreateView() {
      CommonObjApi.getUserSetup().then((res) => {
        this.createView = res.data.isCreateView === "true";
      });
    },
    /**
     * 获取视图Id
     * @param {String} n:创建新的视图id
     */
    getViewId(n) {
      this.newViewId = n;
      if (n) {
        this.getNewViewList();
      }
    },
    /**
     *  取消列表批量操作
     */
    cancelBatchOperation() {
      this.ids = "";
      this.selectedList.map((item) => {
        item.checkedWork = false;
      });
      this.selectedList = [];
    },
    /**
     * 点击报表，在选项卡菜单页面根据对象id获取报表列表
     */
    async reportBoard() {
      // 若是当前报表列表为空，则请求接口获取该对象下的报表列表
      if (this.reportList.length === 0) {
        this.loadingReport = true;
        let params = {
          id: this.objId, // 对象Id
        };
        let { data } = await CommonObjApi.getReportListByObjId(params);
        this.reportList = data;
        this.loadingReport = false;
      }
    },
    /**
     * 获取视图列表，当设置默认视图时，只刷新GetViewList，不刷新表格数据
     * @param {Boolean} viewFlag 是否刷新表格数据
     */
    getViewList(viewFlag) {
      this.viewName = "";
      if (this.$store.state.objectList.tableListConfig) {
        let applicationTab = sessionStorage.getItem("applicationTab");
        let config = this.$store.state.objectList.tableListConfig;
        if (applicationTab === config.applicationId) {
          this.viewId = this.$route.query.viewId || config.viewId; // 视图id
          this.pageSize = config.pageSize; // 每页条数
          this.skippageNum = config.skippageNum; // 当前页数
          this.sortField = config.sortField; // 当前排序字段
          this.sortDir = config.sortField === "" ? "" : config.sortDir; // 当前排序顺序
        } else {
          this.viewId = this.$route.query.viewId || "";
          this.pageSize = 25; // 每页条数
          this.skippageNum = 1; // 当前页数
          this.pageNum = 1; // 当前页数
          this.sortField = ""; // 当前排序字段
          this.sortDir = ""; // 当前排序顺序
          this.cacheListData();
        }
      } else {
        this.viewId = this.$route.query.viewId || ""; // 视图id
      }
      CommonObjApi.GetViewList({ objId: this.objId }).then((res) => {
        this.viewList = res.data;
        this.viewList.map((item) => {
          // 获取列表视图/看板视图带过来的视图信息
          if (item.id === this.viewId) {
            item.isCurrent = true;
            this.viewName = item.label;
            this.viewInfo = item;
            this.ismodifyFalg = item.ismodify;
            this.business();
          } else {
            item.isCurrent = false;
          }
        });

        if (this.viewName === "") {
          this.viewList.map((item) => {
            // 获取默认视图信息
            if (item.isdefault === "1") {
              this.viewName = item.label;
              this.viewId = item.id;
              this.viewInfo = item;
              this.ismodifyFalg = item.ismodify;
              this.business();
            } else {
              item.isCurrent = false;
            }
          });
        }

        // 若接口返回视图列表无默认视图，将第一个视图作为显示视图
        if (this.viewName === "") {
          this.viewList[0].isCurrent = true;
          this.viewName = this.viewList[0].label;
          this.viewId = this.viewList[0].id;
          this.viewInfo = this.viewList[0];
          this.ismodifyFalg = this.viewList[0].ismodify;
          this.business();
        }
        this.viewLength = this.viewList.length;
        if (viewFlag) {
          this.getViewListRecently();
          this.getViewListHeader();
          this.getViewListHeaders();
          this.getViewListData();
          this.getObjectGetObjectInfos();
        }
      });
    },
    /**
     * 编辑、新建视图
     * @param {String} valArr 视图对象
     */
    handleView(valArr) {
      let opration = valArr[0];
      // 新建视图
      if (opration == "create") {
        this.newlyBuild();
      }
      // 编辑视图
      else if (opration == "edit") {
        this.enViewId = valArr[1].id;
        this.editBuild();
      }
      // 复制视图
      else if (opration == "copy") {
        this.$refs.createEditView.copySave(opration);
      }
    },

    /**
     * 初始化数据
     */
    init() {
      this.objForm = {
        label: "",
        filter: "", //高级筛选值
        screeRadio: "0",
        visibRadio: "fsharenone",
        createbyidccname: "", //创建人
        createdate: "", //创建时间
        lastmodifybyidccname: "", //最后修改人
        lastmodifydate: "", //最后修改时间
        list: [
          {
            fieldId: "",
            op: "",
            val: {
              value: "",
            },
            type: 1,
            id: 1,
            inputType: "input",
            optionst_two: [],
            objid: "",
          },
        ],
      };
      this.getViewListHeader();
    },

    /**
     * 新建视图
     */
    newlyBuild() {
      this.viewUnselectedFieldList = [];
      // 新建视图
      this.title = this.$i18n.t("vue_label_commonobjects_view_new_view");
      this.dialongViewTo = true;
      this.viewcell = "creatededit";
      this.visibFlag = false;
      this.selectedFieldIds = "";
      this.selectedGroupList = [];
      this.init();
      this.$nextTick(() => {
        if (this.$refs.createEditView.$refs.oneShuttle !== undefined) {
          this.$refs.createEditView.$refs.oneShuttle.searchVal = "";
        }
        if (this.$refs.createEditView.$refs.shuttles !== undefined) {
          this.$refs.createEditView.$refs.shuttles.searchVal = "";
        }
      });
      this.getViewInfo();
    },

    /**
     * 编辑视图
     */
    editBuild() {
      this.title = this.$i18n.t("label.view.introduce");
      this.viewcell = "creatededit";
      this.init();
      this.objForm.filter = "";
      this.dialongViewTo = true;
      this.getViewInfo();
      this.$nextTick(() => {
        if (
          this.$refs.createEditView.$refs.oneShuttle ||
          this.$refs.createEditView.$refs.shuttles
        ) {
          this.$refs.createEditView.$refs.oneShuttle.searchVal = "";
          this.$refs.createEditView.$refs.shuttles.searchVal = "";
        }
      });
    },

    /**
     * 复制视图
     */
    openCopyView(val) {
      this.enViewId = val;
      this.title = this.$i18n.t("vue_label_commonobjects_view_copy_view");
      this.init();
      this.getViewInfo();
    },

    /**
     * 关闭视图
     */
    closeViewDialog() {
      this.$refs.chooseviewRef.dialogSign = false;
      this.dialongViewTo = false;
    },

    /**
     * 获取视图详细信息
     */
    getViewInfo() {
      // 先做清空
      this.objForm.list = [
        {
          fieldId: "",
          op: "",
          val: {
            value: "",
          },
          type: 1,
          id: 1,
          inputType: "input",
          optionst_two: [],
          objid: "",
        },
      ];
      this.objForm.filter = "";
      this.checkFlag = false;
      this.checked = false;
      const params = {
        //新建时viewid传""
        viewId:
          this.title === this.$i18n.t("vue_label_commonobjects_view_new_view")
            ? ""
            : this.enViewId,
        objId: this.objId,
      };
      this.loading = true;
      CommonObjApi.GetViewInfo(params).then((res) => {
        // if (this.title === "编辑视图" || this.title === "复制视图") {
        if (
          this.title === this.$i18n.t("label.view.introduce") ||
          this.title === this.$i18n.t("vue_label_commonobjects_view_copy_view")
        ) {
          if (res.data.viewInfo) {
            let viewInfoList = res.data.viewInfo;
            //判断是否是复制视图名字
            if (
              this.title ===
              this.$i18n.t("vue_label_commonobjects_view_copy_view")
            ) {
              this.copyForm.name =
                viewInfoList.label + this.$i18n.t("vue_label_norm_duplicate");
            } else {
              this.copyForm.name = "";
            }
            //筛选条件所有人
            if (viewInfoList.filter === "2") {
              this.isQueueSelect = true;
            } else {
              this.isQueueSelect = false;
            }
            this.objForm.screeRadio = viewInfoList.filter;

            //名称
            this.objForm.label = viewInfoList.label;
            //高级筛选表单值
            if (
              res.data.conditionSelectedFieldList &&
              res.data.conditionSelectedFieldList[0] &&
              res.data.conditionSelectedFieldList[0].boolfilter
            ) {
              this.objForm.filter =
                res.data.conditionSelectedFieldList[0].boolfilter;
              this.checkFlag = true;
              this.checked = true;
            } else {
              this.objForm.filter = "";
              this.checkFlag = false;
              this.checked = false;
            }
            //创建人和修改人
            this.objForm.createbyidccname = viewInfoList.createbyidccname;
            this.objForm.createdate = viewInfoList.createdate;
            this.objForm.lastmodifydate = viewInfoList.lastmodifydate;
            this.objForm.lastmodifybyidccname =
              viewInfoList.lastmodifybyidccname;
            if (viewInfoList.isusedformobile === "true") {
              this.isUsedForMobile = true;
            } else {
              this.isUsedForMobile = false;
            }
            //可见性
            if (viewInfoList.accesstype == "fsharegroups") {
              this.objForm.visibRadio = "fsharegroups";
              this.visibFlag = true;
              this.selectedGroupList = res.data.selectedGroupList;
            } else if (viewInfoList.accesstype == "fshareall") {
              this.objForm.visibRadio = "fshareall";
              this.visibFlag = false;
            } else if (viewInfoList.accesstype == "fsharenone") {
              this.objForm.visibRadio = "fsharenone";
              this.visibFlag = false;
            }
          }
          //已选字段
          if (res.data.viewSelectedFieldList) {
            this.viewSelectedFieldList = res.data.viewSelectedFieldList;
            let selecteArr = [];
            res.data.viewSelectedFieldList.forEach((val) => {
              selecteArr.push(val.id);
            });
            this.selectedFieldIds = selecteArr.join(",");
          }
          //已选成员
          const selectedMembList = res.data.selectedGroupList;
          if (selectedMembList) {
            let newArr = [];
            selectedMembList.forEach((val) => {
              newArr.push(val.id);
            });
            this.memberGroup = newArr.join(",");
          }
          //筛选字段下拉框数据
          this.optionst_one = res.data.conditionAllFieldList;
          // 运算符下拉初始化加载
          if (res.data.conditionSelectedFieldList.length) {
            let arr = [];
            res.data.conditionSelectedFieldList.map((item) => {
              this.optionst_one.map((itm) => {
                if (item.fieldId === itm.id) {
                  let inputType =
                    filterValueInputType[
                      itm.schemefieldType === "L" || itm.schemefieldType === "R"
                        ? "Q"
                        : itm.schemefieldType
                    ];
                  // 选择框类型为查找多选字段时，才需要回显做处理
                  if (inputType === "multi-select") {
                    item.val = echoValueTypeConversion(item.val);
                  }
                  arr.push({
                    fieldId: item.fieldId,
                    op: item.op,
                    seq: item.seq,
                    val: {
                      value: item.val,
                    },
                    inputType: inputType,
                    type:
                      itm.schemefieldType === "Z"
                        ? itm.expressionType
                        : itm.schemefieldType,
                    optionst_two:
                      operator.TYPE[
                        itm.schemefieldType === "Z"
                          ? itm.expressionType
                          : itm.schemefieldType
                      ].getScreeningOpeTypes(),
                  });
                }
              });
            });
            // 需要响应式添加
            this.objForm.list = arr;
            // this.$set(this.objForm,'list',arr)
          } else {
            this.objForm.list = [
              {
                fieldId: "",
                op: "",
                val: {
                  value: "",
                },
                type: 1,
                id: 1,
                inputType: "input",
                optionst_two: [],
                objid: "",
              },
            ];
          }
        }
        //筛选字段下拉框数据
        this.optionst_one = res.data.conditionAllFieldList;
        /*
          新建视图：已选字段设置默认为name字段；
          编辑、复制视图：直接取返回的已选字段即可。
        */
        if (
          this.title === this.$i18n.t("vue_label_commonobjects_view_new_view")
        ) {
          //已选字段
          this.viewSelectedFieldList = res.data.viewUnselectedFieldList.filter(
            (itemVal) => {
              return itemVal.name === "name";
            }
          );
          //未选字段
          this.viewUnselectedFieldList =
            res.data.viewUnselectedFieldList.filter((itemVal) => {
              return itemVal.name !== "name";
            });
          this.selLength = this.viewUnselectedFieldList.length;
        } else {
          //未选字段
          this.viewUnselectedFieldList = res.data.viewUnselectedFieldList;
          this.selLength = this.viewUnselectedFieldList.length;
          //已选字段
          if (res.data.viewSelectedFieldList) {
            this.viewSelectedFieldList = res.data.viewSelectedFieldList;
          }
        }
        this.loading = false;
      });
    },

    /**
     * 获取视图列表
     * 新建视图保存后刷新视图列表，将新建视图设置为当前视图
     */
    getNewViewList() {
      this.viewName = "";
      CommonObjApi.GetViewList({
        objId: this.objId,
      }).then((res) => {
        this.viewList = res.data;
        this.viewList.map((item) => {
          // 获取当前视图信息
          if (item.id === this.newViewId) {
            item.isCurrent = true;
            this.viewName = item.label;
            this.viewInfo = item;
            this.ismodifyFalg = item.ismodify;
          } else {
            item.isCurrent = false;
          }
        });
        if (this.viewName === "") {
          this.viewList.map((item) => {
            // 获取默认视图信息
            if (item.isdefault === "1") {
              item.isCurrent = true;
              this.viewName = item.label;
              this.viewId = item.id;
              this.ismodifyFalg = item.ismodify;
              this.viewInfo = item;
            } else {
              item.isCurrent = false;
            }
          });
        } else {
          this.viewId = this.newViewId;
        }

        // 若接口返回视图列表无默认视图，将第一个视图作为显示视图
        if (this.viewName === "") {
          this.viewList[0].isCurrent = true;
          this.viewName = this.viewList[0].label;
          this.viewId = this.viewList[0].id;
          this.viewInfo = this.viewList[0];
          this.ismodifyFalg = this.viewList[0].ismodify;
        }

        this.viewLength = this.viewList.length;
        this.pageNum = 1;
        this.skippageNum = 1;
        this.getViewListRecently();
        this.getViewListHeader();
        this.getViewListHeaders();
        this.getViewListData();
        this.changeView(this.viewInfo);
        this.getObjectGetObjectInfos();
      });
    },
    /**
     * 获取视图列表按钮权限
     */
    getViewListRecently() {
      // document.getElementsByClassName("left-content")[0].style.width = "300px"; //影响页面布局出现大量空白注释掉

      CommonObjApi.getViewListRecently({
        obj: this.prefix,
        viewId: this.viewId,
      }).then((res) => {
        if (res.data) {
          document.title = this.$setTitle(
            `${res.data.viewName} | ${res.data.label}`
          );
          this.tabName = res.data.label;
          this.$store.commit(types.SET_TAB_NAME, this.tabName);

          if (this.prefix === "001") {
            this.tableBtnList.buttonList.splice(
              2,
              1,
              createButton({
                // 更改所有人
                label: this.$i18n.t("label_tabpage_cownerz"),
                action: "transferClientOwner",
              })
            );
          } else {
            this.tableBtnList.buttonList.splice(
              2,
              1,
              //转移所有人
              createButton({
                label: this.$i18n.t("label_tabpage_cownerz"),
                action: "transferOwner",
              })
            );
          }
          // 预计人工成本、实际人工成本不展示操作列
          if (
            this.$cookies.get("activeTabObjId") === "cloudccPlanCost" ||
            this.$cookies.get("activeTabObjId") === "cloudccRealCost"
          ) {
            this.tableBtnList.buttonList = [];
          }
          // 是否启用查找统计
          this.isQueryCount = res.data.isQueryCount;
          this.showBtns = res.data.listBtn.concat(res.data.toolsBtn);
          // 判断是否有导出、打印权限（走导出报表权限）
          this.isExportReport = res.data.isExportReport === "true";
          if (this.isExportReport) {
            // 添加导出、打印按钮
            this.business();
          }
        }
      });
    },
    /**
     * 获取视图详细信息
     */
    searchViewDetail() {
      if (
        !(
          (this.viewId === "0" ||
            this.viewId === "1" ||
            this.viewId === "2" ||
            this.viewId === "3") &&
          this.ismodifyFalg !== "true"
        )
      ) {
        this.getFieldSetup();
      }
    },
    /**
     * 保存视图选中字段
     * @param {Array} ids 字段集合
     */
    saveSelectedField(ids) {
      CommonObjApi.saveFieldSetup({
        objId: this.objId,
        viewId: this.viewId,
        fieldIds: ids,
      }).then(() => {
        this.pageNum = 1;
        this.skippageNum = 1;
        this.getViewListHeader();
        this.getViewListData();
        this.$message.success(this.$i18n.t("label.search.saveok"));
      });
    },
    /**
     * 获取视图详细信息
     */
    getFieldSetup() {
      this.viewSelectedFieldList = [];
      this.viewUnselectedFieldList = [];
      CommonObjApi.getFieldSetup({
        objId: this.objId,
        viewId: this.viewId,
      }).then((res) => {
        this.viewSelectedFieldList = res.data.viewSelectedFieldList;
        this.viewUnselectedFieldList = res.data.viewUnselectedFieldList;
      });
    },
    // 排序字段取列表页视图页字段
    getViewListHeaders(){
      let params = {
        obj: this.prefix,
        viewId: this.viewId,
        tagIds: this.tagIds,
        isAllTag: this.isAllTag,
        searchKeyWord: this.searchKeyWord,
        conditionValues: this.conditionValues,
      };
       CommonObjApi.getViewListHeader(params).then((res) => {
        // PDF视图详情页时，更多操作按钮包括列表视图中表头字段，可以对字段进行排序
        this.fileShowBtns = [];
        if( res.data?.headLabel?.length >0 ){
          res.data.headLabel.map((item) => {
            if (item.name) {
              this.fileShowBtns.push({
                action: "sort",
                label: item.name,
                id: item.id,
                codevalue: item.schemefieldName,
                show: true,
              });
            }
          });
        }
       })
    },
    /**
     * 获取视图列表表头
     */
    getViewListHeader() {
      this.showScreen = false;
      this.isLabelView = false;
      this.isChart = false;
      this.pictLoading = true;
      this.tableAttr = [];
      let params = {
        obj: this.prefix,
        viewId: this.viewId,
        tagIds: this.tagIds,
        isAllTag: this.isAllTag,
        searchKeyWord: this.searchKeyWord,
        conditionValues: this.conditionValues,
        detailTemplate: "true",
      };

      CommonObjApi.getViewListHeader(params).then((res) => {
        this.curHeader = res; //高级筛选器传参
        // 打印数据存储到vuex
        this.$nextTick(() => {
          //解决第一次触发emit无效问题
          this.$store.state.printHeard = this.tableAttr;
        });
        if (
          this.objId === "cloudccPlanCost" ||
          this.objId === "cloudccRealCost"
        ) {
          //项目管理中（实际人工成本和预计人工成本）隐藏新建按钮
          this.addable = false;
        } else {
          this.addable = res.data.objServices.add;
        }

        this.tableAttr = res.data.fieldList;
        this.tableAttr.forEach((item, idx) => {
          item.sortAsc = false;
          item.sortDesc = false;
          item.filterScreen = false;
          // item.popWidth = (item.nameLabel.length + 6) * 15;
          // 名称、查找、主详、查找多选均可跳转
          // 事件及任务下的主题、名称、相关项可跳转
          if (
            item.schemefieldName === "name" ||
            item.schemefieldType === "Y" ||
            item.schemefieldType === "M" ||
            item.schemefieldType === "MR" ||
            item.schemefieldName === "subject" ||
            item.lookupObj === "user" ||
            item.schemefieldName === "whoid" ||
            item.schemefieldName === "relateid"
          ) {
            this.$set(this.tableAttr[idx], "click", "detail");
          }

          // 项目管理-实际工作清单点击日期跳转详情
          if (this.$cookies.get("activeTabObjId") === "projectworklist") {
            if (item.schemefieldType === "D") {
              this.$set(this.tableAttr[idx], "click", "detail");
            }
          }
          this.$set(this.tableAttr[idx], "sortByThis", false);
          this.$set(this.tableAttr[idx], "sortDir", "asc");
          this.$set(this.tableAttr[idx], "fixed", false);
          this.$set(this.tableAttr[idx], "locked", false);
        });

        // this.$refs.tablePanel &&
        //   this.$refs.tablePanel.changeTableAttr(this.tableAttr);
        setTimeout(() => {
          this.pictLoading = false;
        }, 1000);
      });
    },
    /**
     * 刷新
     * @param {String} id 看板id
     */
    refresh(id) {
      this.$refs.kanban && this.$refs.kanban.GetViewKanbanDisplays("", id);
      this.sortField = "";
      this.sortFieldName = "";
      // 重置列表信息
      this.pageObj.dataList = [];
      this.pageNum = 1;
      this.skippageNum = 1;
      this.tagIds = "";
      // 重置列表选中统计数据
      // if (this.$refs.tablePanel) {
      //   this.$refs.tablePanel.checkedList = [];
      // }
      this.selectedList = [];
      this.getViewListHeader();
      this.getViewListData();
    },
    /**
     * 缓存列表数据
     */
    cacheListData() {
      let tableListConfig = {
        prefix: this.prefix, // 视图id
        viewId: this.viewId, // 视图id
        pageSize: this.pageSize, // 每页条数
        skippageNum: this.skippageNum, // 当前页数
        applicationId: sessionStorage.getItem("applicationTab"), // 应用id
        sortField: this.sortField, // 当前排序字段
        sortDir: this.sortField === "" ? "" : this.sortDir, // 当前排序顺序
      };
      this.$store.commit("setTableListConfig", tableListConfig); // 存储列表条件
    },

    /**
     * 获取视图数据
     * @param {Object||String} val 查询条件,标识是否是切换分页重新获取数据，如果val为'noClearn',标识是分页后重新获取数据，此时不需要清空表格选中的数据
     */
    getViewListData(val) {
      this.viewListDataLoading=true;
      if (this.viewId) {
        /**
         * 参数说明
         * 参数名	必选	类型	说明
         * obj	是	string	对象前缀
         * viewId	是	string	视图Id
         * pageNum	是	string	页数
         * pageSize	是	string	每页数量
         * sortField	是	string	排序字段apiname(schemefieldName)
         * sortDir	是	string	排序类型(desc[降序]/asc[升序])
         * searchKeyWord	是	string	搜索关键字
         * conditionValues	是	string	高级搜索条件
         * ischangenum	是	string	是否修改每页查询数目（修改的数目为视图默认每页数量）
         * recordnums	是	string	每页查询默认数目
         * tagIds	否	string	标签id，多个标签逗号分隔，按选中标签查询
         * isAllTag	否	string	是否同时满足所有选中标签（true/false）
         * campaignid	否	string	市场活动成员是否只查询现有成员
         * isallsearch	否	string	是否启用启用全局搜索，conditionValues不用空也不启用全局搜索
         * myself	否	string	全局搜索是否只查询所有人是自己的记录
         * scrollId	否	string	全文检索查询返回值，下一页查询回传
         * type	否	string	contact/lead特殊查询使用以不使用全文检索，参数暂时不用
         */
        this.noDataTip = false;
        if (val && val == "sort") {
          // this.$refs.tablePanel.openFullScreen();
        } else {
          this.pictLoading = true;
        }
        this.cacheListData();
        this.getViewListDataParams = {
          obj: this.prefix,
          viewId: this.viewId,
          pageNum: this.skippageNum, //Y  分页页码
          pageSize: this.pageSize,
          sortField: this.sortField,
          sortDir: this.sortField === "" ? "" : this.sortDir,
          searchKeyWord: this.searchKeyWord,
          conditionValues: this.conditionValues,
          ischangenum: "false",
          recordnums: this.pageSize,
          tagIds: this.tagIds,
          isAllTag: this.isAllTag,
          campaignid: "false",
          isallsearch: this.isallsearch,
          myself: this.myself,
          scrollId: this.scrollId,
          type: "",
          detailTemplate: "true",
          exactSearchFlag: "0",
        };
        CommonObjApi.getViewListData(this.getViewListDataParams).then((res) => {
          this.daochuUrl = res.data.exportUrl;
          if (res.result && res.returnCode === "1") {
            /**
             * 分页
             */
            if (res.data.list.length === 0 && this.pageNum === 1) {
              this.pageObj.dataList = [];
              this.pageNum++;
            } else {
              // 此时应该处理些数据，避免在表格组件中处理数据

              res.data.list.forEach((item) => {
                // 便于cell a标签href使用
                item.nameHrefUrl = `javascript:void(0);`;
                // 给标签添加id
                if (item.cloudcctagList) {
                  item.cloudcctagList.forEach((tagItem, tagIndex) => {
                    tagItem.id = tagIndex + Date.now() + Math.random();
                  });
                }
              });
              res.data.list.forEach((item) => {
                this.$set(item, "checkedWork", false);
              });
              this.pageObj.dataList = res.data.list;
              let chooseListItem=this.pageObj.dataList.find((item) => {
                if (item.id==this.id) {
                  return item
                }
              });
              this.$Bus.$emit("chooseListItem", chooseListItem);
              this.$emit("accountData",res.data.list)

              /**
               * 非切换页码或者 *条/页 的时候表格选中数据清除
               */
              this.selectedList = [];
              this.pageNum++;
            }

            // 判断是否有总数
            if (res.data.isQueryCount === "true") {
              this.totalSize = res.data.totalSize;
              this.maxPageNum = parseInt(this.totalSize / this.pageSize) + 1;
            } else {
              //判断还有没有下一页
              if (res.data.haveMore === "true") {
                //如果有根据页码和每页的数量计算总数
                this.totalSize = (this.skippageNum + 1) * this.pageSize;
                //如果没有下一页
              } else if (
                res.data.haveMore === "false" &&
                this.skippageNum == 1
              ) {
                this.totalSize = res.data.totalSize;
              }
            }
            if (val && val == "sort") {
              // this.$refs.tablePanel.closeFullScreen();
            } else {
              setTimeout(() => {
                this.pictLoading = false;
              }, 1000);
            }
            // setTimeout(() => {
            //   this.$refs.tablePanel &&
            //     this.$refs.tablePanel.$refs.tablePanel &&
            //     this.$refs.tablePanel.$refs.tablePanel.doLayout();
            // }, 2000);
            this.viewListDataLoading=false;
            this.pageObj.totalSize = res.data.totalSize;
          } else {
            this.viewListDataLoading=false;
            this.$message.error(res.returnInfo);
          }
        });
      }
    },
    /**
     * 判断路由是否存在viewId
     * @param {String} view 视图Id
     */
    judgeViewId(view) {
      if (this.$route.query.viewId) {
        let query = this.$router.history.current.query;
        let path = this.$router.history.current.path;
        //对象的拷贝
        let newQuery = JSON.parse(JSON.stringify(query));
        newQuery.viewId = view.id;
        this.$router.replace({ path, query: newQuery });
      }
    },
    /**
     * 切换视图
     * @param {String} view 视图Id
     */
    changeView(view) {
      this.judgeViewId(view);
      this.searchKeyWord = "";
      this.conditionValues = ""; //切换视图时清空高级筛选条件
      this.curPushData = []; //切换视图时清空原有筛选条件
      this.viewList.map((item) => {
        // 获取默认视图信息
        if (item.id === view.id) {
          this.viewName = item.label;
          this.viewId = item.id;
          item.isCurrent = true;
          this.ismodifyFalg = item.ismodify;
          this.viewInfo = item;
          this.business();
        } else {
          item.isCurrent = false;
        }
      });

      if (this.type === "plan") {
        this.$refs.shiftPlan.handleView();
      }
      // 刷新视图列表
      // this.viewId=view.id
      this.refresh(view.id);
      this.getViewListRecently();
      this.getObjectGetObjectInfos();
    },
    /**
     * 新建
     */
    add() {
      this.id = "";
      if (this.objId === "appointment") {
        this.$router.push({
          path: "/scheduleappointment/bookingdetails",
        });
      } else {
        this.isTableEdit = false;
        this.$refs.createEditObj.add();
      }
    },
    /**
     * 编辑
     */
    edit(row) {
      this.id = row.data.id;
      if (this.objId === "appointment") {
        this.$router.push({
          path: "/scheduleappointment/bookingdetails",
          query: {
            id: row.data.id,
          },
        });
      } else {
        this.isTableEdit = true;
        this.$nextTick(() => {
          this.$refs.createEditObj.edit(row);
        });
      }
    },
    /**
     * 转移所有人
     */
    transferOwner(row) {
      this.id = row.data.id;
      this.initTransferOwner(this.objId, this.id);
      this.transferClientOwnerDialog = true;
    },
    /**
     * 更改客户所有人
     */
    transferClientOwner(row) {
      this.id = row.data.id;
      this.initTransferOwner(this.objId, this.id);
      this.transferClientOwnerDialog = true;
    },
    /**
     * 保存转移所有人成功
     * ! 我看混入中使用了
     */
    saveTransferOwnerSuccess() {
      this.pageObj.dataList = [];
      this.pageNum = 1;
      this.getViewListData();
      this.closeTransferOwnerDialog();
    },
    /**
     * 关闭转移所有人Dialog
     */
    closeTransferOwnerDialog() {
      this.transferClientOwnerDialog = false;
    },
    /**
     * 新增/修改保存
     * @param {String} id:修改后数据id，用于表格单条数据更新
     */
    save(id) {
      this.id = id;
      // 开启表格数据单条更新
      this.editFlag = true;
      // 控制表格出现骨架屏
      // this.pageNum = 1;
      this.getViewListData();
    },
    /**
     * 删除
     * @param {Object} row 删除行
     */
    del(row) {
      //项目管理中项目任务删除子任务时修改objectApi
      if (
        this.$route.path.substring(this.$route.path.length - 3) == "p03" &&
        row.data.their_task
      ) {
        this.objectApi = "cloudccSubtask";
      }
      if (this.objectApi === "CloudccProject") {
        //删除该项目，将删除项目和项目关联的所有信息，且数据无法恢复，是否确认删除？
        this.delContent = this.$i18n.t("label.projectManagement.delete.item");
      } else if (this.objectApi === "cloudccMilestone") {
        // 删除该里程碑，将删除里程碑和里程碑关联的所有信息，且数据无法恢复，是否确认删除？
        this.delContent = this.$i18n.t(
          "label.projectManagement.delete.milestone"
        );
      } else if (this.objectApi === "CloudccProblem") {
        //删除该问题，将删除问题和问题关联的所有信息，且数据无法恢复，是否确认删除？
        this.delContent = this.$i18n.t(
          "label.projectManagement.delete.question"
        );
      } else if (this.objectApi === "cloudccTask") {
        // '删除该任务，将删除任务和任务关联的所有信息，且数据无法恢复，是否确认删除？'
        this.delContent = this.$i18n.t("label.projectManagement.delete.task");
      } else if (this.objectApi === "cloudccSubtask") {
        // 删除该子任务，将删除子任务和子任务关联的所有信息，且数据无法恢复，是否确认删除？
        this.delContent = this.$i18n.t(
          "label.projectManagement.delete.subtasks"
        );
      } else {
        this.delContent = this.$i18n.t("label.weixin.confirm.delete");
      }
      this.id = row.data.id;
      this.dialogVisible = true;
    },
    /**
     * 确认删除数据
     */
    confirmDel() {
      this.isBtnLoading = true;
      CommonObjApi.del({ id: this.id, objectApi: this.objectApi })
        .then(() => {
          this.isBtnLoading = false;
          this.pageNum = 1;
          this.dialogVisible = false;
          this.$message.success(this.$i18n.t("label.ems.delete.success"));
          this.getViewListData();
        })
        .catch(() => {
          this.isBtnLoading = false;
        });
    },
    /**
     * 关闭弹窗
     */
    handleClose() {
      this.dialogVisible = false;
    },
    /**
     * 详情
     * @param {Object} row 详情行
     */
    detail(row) {
      // 查找字段跳到对应详情页
      // 事件及任务类型名称和相关项跳转
      if (
        row.field.schemefieldType === "Y" ||
        row.field.lookupObj === "user" ||
        row.field.schemefieldName === "relateid"
      ) {
        CommonObjApi.getPermissionById({
          id: row.data[row.field.schemefieldName],
        }).then((res) => {
          if (!res.data.isQuery) {
            this.$message.warning(
              // "抱歉，您无权查看该条数据，请联系系统管理员。"
              this.$i18n.t("2018UI_Homepage_DataNoAccess_Info")
            );
            return false;
          }
        });
      }
    },
    /**
     * 轮班计划视图
     */
    planView() {
      if (this.$route.path.indexOf("/commonObjects/views/plan") === -1) {
        this.$router.push({
          path: `/commonObjects/views/plan/${this.objectApi}/${this.objId}/${this.prefix}`,
          query: {
            viewId: this.viewId,
          },
        });
        this.$nextTick(() => {
          this.$refs.shiftPlan.renderPage();
        });
      }
    },
    /**
     * 内联编辑
     */
    handleChange() {},
    /**
     * 自动换行
     */
    wordWrap() {
      //
    },
    /**
     * 锁定
     * @param {Object} field 要锁定的字段对象
     */
    locked(field) {
      let fiexdList = this.tableAttr.filter((item) => {
        return item.fixed === true;
      });

      if (fiexdList.length > 2 && field.locked) {
        this.$message.warning(
          this.$i18n.t("vue_label_commonobjects_view_fixed_at_most")
        );
        this.tableAttr.forEach((item) => {
          if (item.id === field.id) {
            item.fixed = false;
            item.locked = false;
          }
        });
      } else {
        this.tableAttr.forEach((item) => {
          if (item.id === field.id) {
            item.fixed = !item.fixed;
          }
        });
      }

      this.$forceUpdate();
      // this.$refs.tablePanel.changeTableAttr(this.tableAttr);
    },
    /**
     * 修改按‘字段名’排序字段
     */
    changeFilterFiled(field) {
      this.filterField = field;
    },
    /**
     * 修改排序字段
     */
    changeSortFiled(field) {
      // this.pageNum = 1
      this.skippageNum = 1;
      // this.pageObj.dataList = []

      // 若排序字段不变，改变排序方式
      if (this.sortField === field.schemefieldName) {
        // this.sortDir = this.sortDir === 'asc' ? 'desc' : 'asc'
      } else {
        // 否则重置排序字段和排序方式
        this.clearSort();
        // this.sortDir = "asc";
        this.sortField = field.schemefieldName;
        this.sortFieldName = field.nameLabel;
      }
      // 刷新视图
      this.getViewListData("sort");
      this.$forceUpdate();
      // this.$refs.tablePanel.changeTableAttr(this.tableAttr);
    },
    /**
     * 清除排序
     */
    clearSort() {
      this.tableAttr.forEach((item) => {
        item.sortByThis = false;
      });
    },

    /**
     * 当前页码发生变化时
     * @param {Number} val 页码
     */
    handleCurrentChange() {
      if(Number(this.skippageNum)+1 > this.maxPageNum){
        this.$message.error("已超出最大页数")
        return 
      }
      this.getViewListData();
      // this.$refs.tablePanel.$refs.tablePanel.bodyWrapper.scrollTop = 0;
    },
    /**
     * 点击回到首页按钮
     */
    goHomePage() {
      this.skippageNum = 1;
      this.getViewListData();
    },
    /**
     * 筛选器：点击按钮及关闭的回调
     */
    filterBoard() {
      this.showScreen = !this.showScreen;
      this.isLabelView = false;
      this.isChart = false;
    },
    /**
     * 高级筛选器修改changeConditionValues传参，高级筛选分页必须从1开始查找，防止按照上次页数查询查出来为空
     * @param {Object} val 当前筛选条件
     * @param {String} curData 当前数据对象
     */
    changeConditionValues(val, curData) {
      this.conditionValues = val;
      this.curPushData = curData;
      if (
        this.viewId === "0" ||
        this.viewId === "1" ||
        this.viewId === "2" ||
        this.viewId === "3"
      ) {
        this.getViewListHeader();
      }
      this.showScreen = true;
      this.pageNum = 1;
      this.skippageNum = 1;
      this.getViewListData();
    },
    /**
     * 标签列表
     */
    tagManage() {
      this.isLabelView = !this.isLabelView;
      this.isChart = false;
      this.showScreen = false;
    },
    /**
     * 图表列表,根据对象id获取图表内的仪表板列表
     */
    async chartsBoard() {
      this.isChart = !this.isChart;
      this.showScreen = false;
      this.isLabelView = false;
      // 判断是否在点击图表按钮展开
      if (this.isChart) {
        // 判断仪表板列表是否有数据
        if (this.listDashboard.length === 0) {
          this.loadingDashboard = true;
          let params = {
            objId: this.objId, // 对象id
          };
          let data = await CommonObjApi.getDashboardListByObjId(params);
          if (data.result) {
            this.listDashboard = data.data.listDashboard;
          } else {
            this.listDashboard = [];
          }
          this.loadingDashboard = false;
        }
      }
    },
    /**
     * 导出视图
     */
    exportView() {
      let urlAry = this.daochuUrl.split("?");
      let num = urlAry[0] + "?";
      let urlParams = urlAry[1].split("&");
      urlParams.forEach((item) => {
        let ary = item.split("=");
        if (ary[0] === "page") {
          num = num + `page=${this.skippageNum}&`;
        } else if (ary[0] === "pageSize") {
          num = num + `pageSize=${this.pageSize}&`;
        } else {
          num = num + item + "&";
        }
      });
      num = num.slice(0, num.length - 1);
      var link = document.createElement("a");
      link.href = num;
      link.setAttribute("download", num);
      link.click();
    },
    /**
     * 视图打印
     */
    print() {
      this.searchKeyWord = this.searchKeyWord ? this.searchKeyWord : null;
      let tagIdsArr = this.tagIds ? this.tagIds.replace(/,/g, "&") : null;
      window.open(
        `#/print-table/${this.objId}/${this.prefix}/${this.objectApi}/${this.viewId}/${this.skippageNum}/${this.pageSize}/${this.searchKeyWord}/${tagIdsArr}`,
        "_parent - URL",
        "height=700, width=1210, top=50, left=100, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=n o, status=no"
      );
    },
    getMsgFormSon(data) {
      this.isLabelView = data;
    },
    /**
     * 筛选调用数据刷新
     */
    recall() {
      this.showScreen = false;
      if (this.$refs.shiftPlan) {
        this.$refs.shiftPlan.handleSearch();
      }
      this.pageNum = 1;
      this.skippageNum = 1;
      this.getViewListData();
    },
    /**
     * 设置视图刷新数据
     */
    refreshView() {
      this.getNewViewList();
    },
    /**
     * 标签页向父组件传值
     */
    tagValues(tagValue) {
      this.tagIds = tagValue;
      this.skippageNum = 1;
      this.getViewListData();
    },
    /**
     * 打开看板
     */
    actives() {
      this.$refs.kanban.open();
    },
    /**
     * 添加tags
     */
    addTag() {
      if (this.selectedList.length > 200 || this.lengths > 200) {
        this.$message.info(
          this.$i18n.t("vue_label_commonobjects_view_up_to_data")
        );
      } else {
        if (this.ids === "") {
          this.$message.info(this.$i18n.t("lable.opppdt.selPdt.leastone"));
        } else {
          this.$refs.addlabel.isShows();
        }
      }
    },
    /**
     * 获取新添加的标签
     */
    determineFun() {
      this.$refs.addlabel.isShows();
      this.getTagsByRecordId();
    },
    /**
     * 关闭添加按钮
     */
    closeAddBtn() {
      this.$refs.addlabel.isShows();
    },
    /**
     * 通过记录id获取标签集合
     */
    getTagsByRecordId() {
      CommonObjApi.GetTagsByRecordId({
        objId: this.objId,
        recordId: this.id,
      }).then((res) => {
        this.labelArr = res.data;
      });
    },
    /**
     * 取消图表
     */
    quxiao() {
      this.isChart = false;
    },
    /**
     * 表格选中数据
     * @param {Array} val 当前选中的数据集合
     */
    onchange() {
      this.selectedList = [];
      this.pageObj.dataList.map((item) => {
        if (item.checkedWork) {
          this.selectedList.push(item);
        }
      });
      if (this.bool == false) {
        this.selectedList = this.$store.state.checkedNum;
      }
      let ids = "";
      for (var i = 0; i < this.selectedList.length; i++) {
        ids += this.selectedList[i].id + ",";
      }
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (ids.length > 0) {
        ids = ids.substr(0, ids.length - 1);
      }
      this.ids = ids;
      // 如果有选中的数据，隐藏视图切换器
      this.showFilter = this.selectedList.length === 0;
    },
    chat(val) {
      this.ids = val;
    },
    /**
     * 获得经度
     * @param {Object} val 经度值
     */
    longitude(val) {
      this.lengths = val;
    },
    /**
     * 判断按钮权限
     */
    business() {
      this.showFilter = false;
      this.viewsTypeList = [
        {
          icon: "List",
          name: this.$i18n.t("vue_label_help_list"),
        },
        {
          icon: "splitscreen",
          name: this.$i18n.t("vue_label_commonobjects_view_split_screen_view"),
        },
      ];
      this.showButtons = [];
      if (this.$route.query.objectApi === "Opportunity") {
        // 业务机会对象，可展示【销售漏斗、销售阶段推进】
        this.showButtons.push({
          label: this.$i18n.t("label.mobile.quickcreate.default.sellingfunnel"),
          action: "salesFunnel",
        });
        this.showButtons.push({
          label: this.$i18n.t("c184"),
          action: "promotionOfSalesStage",
        });
      }
      if (this.$route.query.type === "table") {
        // 对象已启用且属于列表视图，可展示【标签】
        this.showFilter = true;
        this.showButtons.push({
          label: this.$i18n.t("c183"),
          action: "tagManage",
        });
      }
      if (
        !(
          this.viewInfo.id === "0" ||
          this.viewInfo.id === "1" ||
          this.viewInfo.id === "2" ||
          this.viewInfo.id === "3"
        )
      ) {
        // 非四个特殊视图，可展示【看板视图】、【图表】
        this.viewsTypeList.push({
          icon: "Kanban",
          name: this.$i18n.t("vue_label_commonobjects_view_kanban_view"),
        });
        this.showButtons.push({
          label: this.$i18n.t("vue_label_commonobjects_view_chart"),
          action: "chartsBoard",
        });
      }
      // 先对按钮集合赋初始值,导出和打印视图（权限走导出报表权限）
      if (this.isExportReport) {
        this.showButtons.push(
          {
            label: this.$i18n.t("label.tabpage.exportview"),
            action: "export",
          },
          {
            label: this.$i18n.t("label.tabpage.printview"),
            action: "print",
          }
        );
      }

      //客户和潜在客户对象添加开启/关闭公海池按钮
      if (
        (this.prefix == "001" || this.prefix == "004") &&
        this.$store.state.userInfoObj.manageSetup == "true"
      ) {
        this.showButtons.push({
          label:
            this.highSeaPoolStatus &&
            this.highSeaPoolStatus.marketseaState == "true"
              ? this.$i18n.t("c916")
              : this.$i18n.t("c915"),
          action: "highSeasPoolStatus",
        });
      }
    },
    /**
     * 销售漏斗
     */
    salesFunnel() {
      const newPage = this.$router.resolve({
        path: `/commonObjects/hopper`,
      });
      window.open(newPage.href, "_blank");
    },
    /**
     * 销售阶段推进
     */
    promotionOfSalesStage() {
      const newPage = this.$router.resolve({
        path: `/commonObjects/upgrade-hopper`,
      });
      window.open(newPage.href, "_blank");
    },
    /**
     * 获取新的视图列表
     * @param {String} val 新视图id
     */
    viewIds(val) {
      this.newViewId = val;
      this.getNewViewList();
    },
    /**
     * 重置数据
     */
    recalles() {
      this.pageNum = 1;
      this.skippageNum = 1;
      this.recall();
    },
    /**
     * 获取对象的tag信息
     */
    async getObjectGetObjectInfos() {
      let params = {
        id: this.objId,
      };
      let res = await CommonObjApi.getObjectGetObjectInfo(params);
      if (res.result) {
        this.isTag = res.data.istag;
      }
    },
    /**
     * 获取看板视图展示数据
     * @param {String} val 搜索的关键字
     */
    async GetViewKanbanDisplays(val) {
      let params = {
        viewId: this.viewId,
        searchKeyWord: val,
        recordType: this.activeNames,
      };
      let res = await CommonObjApi.GetViewKanbanDisplay(params);
      if (res.result) {
        this.kanBanData = res.data;
      }
    },
    /**
     * 看板tab值
     * @param {String} val tab值
     */
    activeName(val) {
      this.activeNames = val;
    },
    /**
     * 打开MailChimp弹框
     */
    mailchimp() {
      this.$refs.MailChimp.open();
    },
    /**
     * 全选列表数据
     * @param {Boolean} val 全选状态
     */
    MailChimpSelect(val) {
      this.bool = val;
      // if (val === true) {
      //   this.$refs.tablePanel.$refs.tablePanel.toggleAllSelection();
      // } else {
      //   this.$refs.tablePanel.$refs.tablePanel.clearSelection();
      //   this.selectedList = this.$store.state.checkedNum;
      //   this.$store.state.checkedNum.forEach((item) => {
      //     this.$refs.tablePanel.$refs.tablePanel.toggleRowSelection(item, true);
      //   });
      // }
    },
    /**
     * 从项目模板新建 保存 刷新
     */
    createdtemplainit() {
      this.getViewListData();
    },
    /**
     * 操作项 标记模板
     * @param {Object} item 当前项
     */
    marktemplate(val) {
      let marketep = "";
      if (val.action == "unmarktemplate") {
        marketep = false;
      } else {
        marketep = true;
      }
      let params = {
        id: val.data.id,
        template: marketep,
      };
      CommonObjApi.modifyProjectTemplate(params).then((res) => {
        if (res.result) {
          this.$message.success(res.returnInfo);
          // this.$refs.tablePanel.checkboxmarktemplate(val.action);
        }
      });
    },

    /**
     * 判断队列是否展示
     */
    async checkQueueAndPartners() {
      let params = {
        id: this.objId,
      };
      let res = await CommonObjApi.checkQueueAndPartner(params);
      if (res.result) {
        this.isQueue = res.data.isQueue;
        if (res.data.isQueue) {
          this.queryQueueLists();
        }
      }
    },

    /**
     * 获取队列列表
     */
    async queryQueueLists() {
      let params = {
        id: this.objId,
      };
      let res = await CommonObjApi.queryQueue(params);
      if (res.result) {
        this.queryQueueList = res.data;
      }
    },
    /**
     * 判断客户与潜在客户下公海池是否开启
     */
    async getHighSeaPoolStatus() {
      if (this.prefix == "001" || this.prefix == "004") {
        let result = await getMarketseaSwitchState();
        if (result.result && result.data.length > 0) {
          this.highSeaPoolStatus = result.data.filter(
            (res) => res.objid == this.objId
          )[0];
          // 防止this.highSeaPoolStatus不是对象
          if (!this.highSeaPoolStatus) {
            this.highSeaPoolStatus = {};
          }
        }
      }
    },
    /**
     * 开启/关闭公海池操作
     */
    highSpoolAction(action) {
      if (action == "highSeasPoolStatus") {
        this.highSeasPdialog = true;
      }
    },
    /**
     * PDF视图字段排序
     */
    fileSortFun(file, sort) {
      this.sortField = file;
      this.sortDir = sort;
      this.skippageNum = 1;
      // 刷新视图
      this.getViewListData("sort");
      this.$forceUpdate();
    },
    /**
     * 关闭公海池弹框
     */
    closeDialog(status) {
      this.highSeasPdialog = false;
      //公海池开启状态更改后，更改按钮文字
      if (status) {
        this.highSeaPoolStatus.marketseaState = status;
        this.showButtons.forEach((res) => {
          if (res.action == "highSeasPoolStatus") {
            res.label =
              status == "false" ? this.$i18n.t("c915") : this.$i18n.t("c916");
          }
        });
      }
    },
    /**
     * 设置顶部按钮功能操作区域左侧宽度,在切换选中数据时，判断是否展示更多按钮
     */
    settingWidth() {
      if (
        document.getElementsByClassName("object_box") &&
        document.getElementsByClassName("object_box")[0].clientWidth &&
        document.getElementsByClassName("right-content") &&
        document.getElementsByClassName("right-content")[0].clientWidth &&
        document.getElementsByClassName("left-content")
      ) {
        // 整体宽度
        let topWidth =
          document.getElementsByClassName("object_box")[0].clientWidth;
        // 右侧宽度，左右间隙20
        let rightWidth =
          document.getElementsByClassName("right-content")[0].clientWidth + 20;
        // 设置右侧宽度
        document.getElementsByClassName("left-content")[0].style.width = `${
          topWidth - rightWidth
        }px)`;
      }
    },
  },
  watch: {
    prefix: {
      handler() {
        this.selectedList = [];
        //判断公海池状态
        this.getHighSeaPoolStatus();
      },
      immediate: true,
    },
    tabName() {
      // 列表表头日历更改为事件
      if (this.objectApi === "Event") {
        this.tabName = this.$i18n.t("label.event");
      }
    },
    // 获取路由
    $route: {
      handler: function (route) {
        // 获取到项目任务页面对象api
        this.projectTasksObjectApi = route.params.objectApi;
      },
      immediate: true,
    },
    // 监听表格选中数据条数
    selectedList() {
      // 给定批量操作按钮最大宽度并处理按钮展示逻辑
      // 设置顶部按钮功能操作区域左侧宽度
      this.settingWidth();
      this.$nextTick(() => {
        this.$refs.batchOperationButtons.showMoreBtns();
      });
    },
  },
  beforeDestroy() {
    localStorage.setItem("relevantObjectLevel", 0);
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table::before {
  height: 0;
}

::v-deep .el-table__fixed::before {
  height: 0;
}
::v-deep .el-tabs__active-bar is-top {
  padding: 0 20px !important;
}
.el-tabs__active-bar {
  padding: 0 20px !important;
}

.groupTitle {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  padding-left: 20px;
  background: #f0f0f0;
}

.objectTableMain {
  border-left: none;
  border-right: none;
  border-radius: 3px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  .object_box {
    border-radius: 3px;
    border: 1px solid #dedcda;
    flex: 1;
    height: 100%;
    // overflow: auto;
    background: #fff;
    display: flex;
    flex-direction: column;
  }
}

/* 标签列表位置 */
.LabelView {
  // height: 88%;
  position: absolute;
  right: 11px;
  top: 111px;
  z-index: 99;
}

/* 图表位置 */
.chart {
  width: 300px;
  height: calc(100% - 116px);
  // height: 88%;
  position: absolute;
  right: 11px;
  top: 105px;
  z-index: 99;
}

.topContent {
  display: flex;
  justify-content: space-between;
  background: #f5f5f5;
  border-bottom: 1px solid #e1e1e1;
}

.left-content {
  padding: 15px 0px 16px 10px;
  display: flex;
}

.right-content {
  padding: 15px 20px 16px 0;
  display: flex;
}

.add_label {
  position: absolute;
  top: 57px;
  left: 35%;
}
.selectnum {
  height: 12px;
  font-size: 12px;
  margin-top: -20px;

  font-weight: 400;
  color: #666666;
  padding-bottom: 22px;
}
.checkboxs {
  margin-left: 6px;
  font-size: 12px;
  line-height: 28px;
}
.spancolor {
  color: #fa6400;
}
.spans {
  height: 12px;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
  padding-top: 12px;
}
.mtransferli {
  list-style: inside;
  height: 34px;
  font-size: 12px;

  font-weight: 400;
  color: #0c0b0b;
  line-height: 18px;
}
/*批量新增css*/
.tank {
  height: 480px;
  background: rgba(255, 255, 255, 1);
  z-index: 6;
  margin: 100px auto 0;
}
.title {
  height: 53px;
  line-height: 53px;
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  border-bottom: 1px solid rgba(240, 240, 240, 1);
  color: rgba(109, 114, 120, 1);
  font-size: 18px;
}
.btnp {
  font-weight: bold;
  font-size: 17px;
}
.btnx {
  font-size: 16px;
}

.listulFir {
  height: 40px;
  min-width: 100%;
  border: 1px solid rgba(190, 225, 255, 1);
  border-left: none;
  display: inline-flex;
  background: rgba(222, 240, 253, 1);
}
.listulSec:nth-of-type(2n) {
  height: 40px;
  display: inline-flex;
  background: rgba(240, 248, 255, 1);
  min-width: 100%;
}
.listulSec:nth-of-type(2n + 1) {
  height: 40px;
  display: inline-flex;
  background: white;
  min-width: 100%;
}
.firstLi {
  border-right: 1px solid rgba(190, 225, 255, 1);
  min-width: 80px;
  text-align: center;
}
.secondLi {
  border-right: 1px solid rgba(190, 225, 255, 1);
  text-align: center;
  width: 180px;
  line-height: 40px;
}
::v-deep.el-pagination {
  margin-bottom:4px!important;
}
::v-deep .el-input {
  height: 30px;
  width: 100%;
  text-align: center;
}
::v-deep .el-input__inner {
  height: 30px!important;
  width: 100%;
}
::v-deep .el-button--mini {
  padding: 7px 7px;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: rgba(51, 51, 51, 1);
}
::v-deep .el-input__icon {
  height: auto;
}
.al {
  margin: 0 24px;
  width: auto;
  overflow: auto;
  max-height: 250px;
  border: 1px solid rgba(190, 225, 255, 1);
}
::v-deep .el-input__icon {
  margin-top: -4px;
}
.tianjia {
  margin-top: 20px;
  cursor: pointer;
  width: 32px;
  margin-bottom: 10px;
  margin-left: 16px;
}
ul {
  margin-bottom: 0;
}
.block {
  background: #fff;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  z-index: 9;
}

.main-content-box {
  display: flex;
  height: calc(100% - 64px);
  flex-direction: column;
  // border-top: 1px solid #e1e1e1;

  /* 筛选器位置 */
  .screen {
    height: 100%;
    border-right: 1px solid #bbb;
  }

  .tablePanel {
    flex: 1;
    overflow: auto;
  }
  .data-content {
    width: 100%;
    background-color: #fff;
    height: calc(100% - 60px);
    overflow-y: auto;
    ::v-deep .el-checkbox__label {
      width: 100%;
    }
    .checkoutbox {
      padding: 0 9px 0 13px;
      height: 30px;
    }
    .list-active {
      background: #f3f7ff;
    }
    .quate-list {
      display: flex;
      min-height: 65px;
      border-bottom: 1px solid #e1e1e1;
      border-right: 1px solid #e1e1e1;
      font-size: 14px;
      .check-box {
        display: flex;
        align-items: center;
        padding: 0 9px 0 13px;
      }
      .title-number {
        width: 100%;
        padding: 0 16px 0 0;
      }
      .quate-title {
        display: flex;
        justify-content: space-between;
        padding: 12px 0 6px 0;
        min-height: 18px;
        .quate-name {
          color: #000000;
          max-width: 190px;
          font-weight: 400;
          min-height: 18px;
          font-size: 12px;
          white-space: normal;
        }
        .quote-total {
          min-height: 18px;
          color: #080707;
          font-size: 12px;
        }
      }
    }
    .quate-list:hover{
      background: #f3f7ff;
    }
    .quate-number {
      display: flex;
      justify-content: space-between;
      padding-bottom: 12px;
      font-size: 12px;
      .number-date {
        display: flex;
      }
      .blue-number {
        color: #2d6cfc;
        margin-right: 10px;
      }
      .status-blue {
        color: #2d6cfc;
      }
      .status-grey {
        color: #666666;
      }
      .status-orange {
        color: #fe8a14;
      }
      .status-red {
        color: #f11b21;
      }
      .border-solid {
        padding-left: 14px;
        border-left: 1px solid #e1e1e1;
        color: #666666;
      }
    }
  }
  .paginationBox {
    height: 59px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    .pagination-page {
      display: flex;
      align-items: center;
    }
    .add-pagenum {
      display: flex;
      align-items: center;
      div {
        margin: 0 5px;
        color: #666666;
      }
      ::v-deep input::-webkit-outer-spin-button,
      ::v-deep input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
        margin: 0;
      }
      ::v-deep .el-input__inner {
        padding: 0 5px;
      }
      input::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
    }
    .pagination-total {
      color: #2d6cfc;
    }
  }
}

.go_homepage {
  margin-left: 20px;
  cursor: pointer;
  color: #006dcc;
}
</style>

import axios from '@/config/httpConfig'

// 获取视图列表
export function GetViewList (data) {
    return axios.post('/view/list/getViewList', data)
  }
// 选项卡菜单页面根据对象id获取报表列表
export function getReportListByObjId (data) {
    return axios.post('/report/getReportListByObjId',data)
  }
// 获取视图详细信息
export function GetViewInfo (data) {
    return axios.post('/view/getViewInfo', data)
  }
//根据记录id获取标签
export function GetTagsByRecordId (data) {
    return axios.post('/tag/getTagsByRecordId', data)
  }
// 获取对象信息
export function getObjectGetObjectInfo (data) {
    return axios.post('/object/getObjectInfo',data)
  }
// 根据对象id获取图表中的仪表板列表
export function getDashboardListByObjId (data) {
    return axios.post('/dashboard/getDashboardListByObjId', data)
  }
// 看板视图展示
export function GetViewKanbanDisplay (data) {
    return axios.post('/viewKanban/display', data)
  }
// 获取用户界面设置
export function getUserSetup(data) {
    return axios.get('/user/getUserSetup', data)
  }
  // 获取视图列表页面信息
  export function getViewListRecently(data) {
    return axios.post('/view/list/getViewListRecently', data)
  }
  // 保存视图字段设置
  export function saveFieldSetup(data) {
    return axios.post('/view/saveFieldSetup', data)
  }
  // 获取视图字段设置
  export function getFieldSetup(data) {
    return axios.post('/view/getFieldSetup', data)
  }
  // 获取视图列表表头
  export function getViewListHeader(data) {
    return axios.post('/view/list/getViewListHeader', data)
  }
  // 获取视图列表记录
  export function getViewListData(data) {
    return axios.post('/view/list/getViewListAjax', data)
  }
  //获取更改所有人页面信息-单条数据
  export function getChangeOwner(data) {
    return axios.post('/transfer/getChangeOwner', data)
  }
  // 删除记录
  export function del(data) {
    return axios.post('/objectdetail/delete', data)
  }
  //获取对象记录权限
  export function getPermissionById(data) {
    return axios.post('/objectdetail/getPermissionById', data)
  }
  // 标记/取消标记为模板
  export function modifyProjectTemplate(data) {
    return axios.post('/projectTemplate/modifyProjectTemplate', data)
  }
  // 更改所有人是否展示伙伴云和队列
  export function checkQueueAndPartner(data) {
    return axios.post('/objectdetail/checkQueueAndPartner', data)
  }
  // 队列
  export function queryQueue(data) {
    return axios.post('/objectdetail/queryQueue', data)
  }
  // 文件预览
  export function viewFile(data) {
    return axios.post('file/preview', data)
  } 
  //保存模板到文件
  export function saveTemplatePdf(data) {
    return axios.post('/cpq/template/saveTemplatePdf', data)
  }
  // 接口17 预览生成的PDF文件
  export function getTemplatePdf(data) {
  return axios.post('/cpq/template/getTemplatePdf', data)
  }